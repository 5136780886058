<template>
  <div class="base">
    <div class="card-title">报修描述</div>
    <div class="card">
      <div class="card-cell">
        <div class="cell-title">
          <span class="required">*</span>
          <span>设备名称</span>
        </div>
        <div class="cell-content">{{ dataObj.title }}</div>
      </div>
      <div class="card-cell">
        <div class="cell-title">
          <span class="required">*</span>
          <span>报修位置</span>
        </div>
        <div class="cell-content">{{ dataObj.position }}</div>
      </div>
      <div class="card-cell textarea-cell">
        <div class="cell-title">
          <span class="required">*</span>
          <span>详情描述</span>
        </div>
        <div class="cell-content">{{ dataObj.details }}</div>
      </div>
      <div class="card-cell">
        <div class="cell-title">
          <span class="required">*</span>
          <span>报修联系人</span>
        </div>
        <div class="cell-content">{{ dataObj.contacts }}</div>
      </div>
      <div class="card-cell">
        <div class="cell-title">
          <span class="required">*</span>
          <span>联系电话</span>
        </div>
        <div class="cell-content">{{ dataObj.contactsMobile }}</div>
      </div>
    </div>
    <div class="card-title">现场照片</div>
    <div class="card">
      <div class="card-cell imgs-cell" v-if="repairImg.length > 0">
        <img class="up-img" v-for="(item, index) in repairImg" :key="index" :src="item" @click="showImg(item)" />
      </div>
      <div class="card-cell imgs-cell" v-if="repairImg.length == 0">
        <div class="no-img">未上传现场照片</div>
      </div>
    </div>
    <div class="card-title">报修处理</div>
    <div class="card">
      <div class="card-cell textarea-cell">
        <div class="cell-title">
          <span class="required">*</span>
          <span>维修描述</span>
        </div>
        <textarea
          class="cell-content textarea"
          placeholder="请输入维修描述"
          v-model="dataObj.handleExplain"
          maxlength="300"
          rows="4"
        ></textarea>
      </div>
      <div class="imgs-cell-title">
        <span class="required">*</span>
        <span>维修照片</span>
      </div>
      <div class="card-cell up-imgs-cell">
        <div class="up-img-cell" v-for="(item, index) in fileList" :key="index">
          <img class="up-img" :src="imgUrl(item)" alt="" @click="showImg(item)" />
          <img class="remove-img" @click="onRemoveFile(index)" src="../../../assets/img/enterprise/remove.png" alt="" />
        </div>
        <div class="up-img-cell" v-if="fileList.length <= 2">
          <upload-img @sendOrgImg="getOrgImg"></upload-img>
        </div>
      </div>
      <div class="card-cell">
        <div class="cell-title">
          <span class="required">*</span>
          <span>是否使用配件</span>
        </div>
        <div class="cell-content">
          <el-radio-group v-model="dataObj.isParts">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </div>
      </div>
      <div class="card-cell" v-if="dataObj.isParts === 1">
        <div class="cell-title">
          <span class="required">*</span>
          <span>使用配件</span>
        </div>
        <input class="cell-content" placeholder="请输入使用的配件信息" v-model="dataObj.consumables" maxlength="20" />
      </div>
    </div>
    <div class="but-cell">
      <mt-button class="save-but" :loading="submiting" @click="onSubmit" type="primary">提交</mt-button>
    </div>
    <mt-popup v-model="showImgFlag">
      <img :src="tempImgUrl" class="big-img" />
    </mt-popup>
  </div>
</template>

<script>
import UploadImg from '@/components/UploadImg'
import { Indicator, Toast } from 'mint-ui'
import { editRepairAudit, getRepairData, upload } from '@/api/enterprise'

export default {
  components: { UploadImg },
  data() {
    return {
      id: '',
      submiting: true,
      repairImg: [],
      fileList: [],
      handleImagePath: [],
      dataObj: { handleExplain: '', isParts: 0, consumables: '' },
      showImgFlag: false,
      tempImgUrl: '',
      options: [
        {
          label: '是',
          value: '1'
        },
        {
          label: '否',
          value: '0'
        }
      ]
    }
  },
  created() {
    this.id = this.$route.query.id
    this.getDetail()
  },
  methods: {
    getDetail() {
      const that = this
      Indicator.open('请稍等...')
      getRepairData({ id: that.id })
        .then((res) => {
          that.dataObj = res.result
          if (res.result.imagePath) {
            that.repairImg = res.result.imagePath.split(',')
          }
        })
        .catch((err) => {
          that.requestFailed(err)
        })
        .finally(() => {
          Indicator.close()
        })
    },
    onRemoveFile(index) {
      this.fileList.splice(index, 1)
    },
    async onSubmit() {
      if (this.dataObj.isParts === null || this.dataObj.isParts.length === 0) {
        Toast('请选择是否使用配件')
        return
      }
      if (this.dataObj.isParts === 1 && !this.dataObj.consumables) {
        Toast('请输入使用的配件信息')
        return
      }
      if (!this.dataObj.handleExplain) {
        Toast('请输入维修描述')
        return
      }
      if (this.fileList.length === 0) {
        Toast('请上传维修图片')
        return
      }
      Indicator.open()
      this.submiting = true
      await this.uploadImgs()
      const fd = new FormData()
      fd.append('id', this.id)
      fd.append('repairStatus', 1)
      fd.append('isParts', this.dataObj.isParts)
      if (this.dataObj.consumables) {
        fd.append('consumables', this.dataObj.consumables)
      }
      fd.append('handleExplain', this.dataObj.handleExplain)
      fd.append('handleImagePath', this.handleImagePath.join(','))
      try {
        const __params = {}
        for (const [k, v] of fd.entries()) {
          __params[k] = v
        }
        await editRepairAudit(__params)
        Toast('提交成功')
        await this.$router.go(-1)
      } catch (error) {
        // Toast('请求失败')
      } finally {
        this.submiting = false
      }
    },
    // 错误请求
    requestFailed(err) {
      console.error({
        message: '错误',
        description: ((err.response || {}).data || {}).message || '请求出现错误，请稍后再试',
        duration: 4
      })
    },
    showImg(item) {
      this.showImgFlag = true
      this.tempImgUrl = item
    },
    getOrgImg(data) {
      this.fileList.push(data)
    },
    async uploadImgs() {
      const that = this
      for (let i = 0; i < this.fileList.length; i++) {
        try {
          const fd = new FormData()
          fd.append('file', this.fileList[i])
          const { success, message } = await upload(fd)
          if (success) {
            that.handleImagePath.push(message)
          }
        } catch (error) {
          Toast('图片' + (i + 1) + '上传失败')
        } finally {
          this.submiting = false
          Indicator.close()
        }
      }
    },
    imgUrl(item) {
      return URL.createObjectURL(item)
    }
  }
}
</script>

<style lang="less" scoped>
input,
input:hover,
input:focus {
  border: none;
  text-align: right;
}

textarea,
textarea:hover,
textarea:focus {
  border: none;
  width: 100%;
}

.base {
  background: #f7f8fc;
  min-height: 100vh;
  font-weight: 'Source Han Sans CN';

  .card-title {
    font-weight: 400;
    font-size: 15px;
    color: #999999;
    padding: 0px 20px;
    height: 55px;
    line-height: 55px;
  }

  .card {
    background: #ffffff;
    padding: 0px 20px;
    box-shadow: 0px 0px 2px 0px rgba(23, 140, 249, 0.1);

    &-cell {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #f7f8fc;

      .cell-title {
        height: 50px;
        line-height: 50px;
      }

      .required {
        color: red;
      }

      .cell-content {
        width: 220px;
        font-size: 14px;
        line-height: 30px;
        text-align: right;

        /deep/ .el-radio__label {
          font-size: 15px;
        }
      }
    }

    .textarea-cell {
      flex-direction: column;
      align-items: flex-start;

      .cell-content {
        width: 100%;
        min-height: 149px;
        text-align: left;
        background: rgba(242, 242, 242, 0.8);
        border-radius: 10px;
        padding: 5px 10px;
        line-height: 35px;
        display: -webkit-box;
        -webkit-box-orient: vertical; /* 设置或检索伸缩盒对象的子元素的排列方式 */
        overflow: hidden;
        white-space: normal;
        text-overflow: ellipsis;
        word-wrap: break-word;
        -webkit-line-clamp: 3;
      }

      .textarea {
        width: 315px;
        padding-bottom: 0px;
      }
    }

    .imgs-cell-title {
      height: 50px;
      line-height: 50px;
      margin-bottom: -20px;

      .required {
        color: red;
      }
    }

    .imgs-cell {
      justify-content: flex-start;

      .up-img {
        margin: 20px 17.5px 20px 0px;
        width: 100px;
        height: 100px;
      }

      .up-img:last-child {
        margin-right: 0px;
      }

      .no-img {
        color: #999999;
        line-height: 50px;
        height: 50px;
      }
    }

    .up-imgs-cell {
      justify-content: flex-start;

      .up-img-cell {
        position: relative;

        .up-img {
          margin: 20px 17.5px 20px 0px;
          width: 100px;
          height: 100px;
        }

        .up-img:last-child {
          margin-right: 0px;
        }

        .remove-img {
          position: absolute;
          right: 6px;
          top: 8px;
          width: 25px;
          height: 25px;
        }

        .hidden-up-but {
          position: absolute;
          top: 20px;
          left: 0px;
          width: 100px;
          height: 100px;
          opacity: 0;
        }
      }
    }
  }

  .but-cell {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 50px;

    .save-but {
      width: 335px;
      background-color: #1073e3;
      margin: 20px 0;
    }
  }

  .big-img {
    width: 375px;
  }
}
</style>
