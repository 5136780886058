var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "base" },
    [
      _c("div", { staticClass: "card-title" }, [_vm._v("报修描述")]),
      _c("div", { staticClass: "card" }, [
        _c("div", { staticClass: "card-cell" }, [
          _vm._m(0),
          _c("div", { staticClass: "cell-content" }, [
            _vm._v(_vm._s(_vm.dataObj.title))
          ])
        ]),
        _c("div", { staticClass: "card-cell" }, [
          _vm._m(1),
          _c("div", { staticClass: "cell-content" }, [
            _vm._v(_vm._s(_vm.dataObj.position))
          ])
        ]),
        _c("div", { staticClass: "card-cell textarea-cell" }, [
          _vm._m(2),
          _c("div", { staticClass: "cell-content" }, [
            _vm._v(_vm._s(_vm.dataObj.details))
          ])
        ]),
        _c("div", { staticClass: "card-cell" }, [
          _vm._m(3),
          _c("div", { staticClass: "cell-content" }, [
            _vm._v(_vm._s(_vm.dataObj.contacts))
          ])
        ]),
        _c("div", { staticClass: "card-cell" }, [
          _vm._m(4),
          _c("div", { staticClass: "cell-content" }, [
            _vm._v(_vm._s(_vm.dataObj.contactsMobile))
          ])
        ])
      ]),
      _c("div", { staticClass: "card-title" }, [_vm._v("现场照片")]),
      _c("div", { staticClass: "card" }, [
        _vm.repairImg.length > 0
          ? _c(
              "div",
              { staticClass: "card-cell imgs-cell" },
              _vm._l(_vm.repairImg, function(item, index) {
                return _c("img", {
                  key: index,
                  staticClass: "up-img",
                  attrs: { src: item },
                  on: {
                    click: function($event) {
                      return _vm.showImg(item)
                    }
                  }
                })
              }),
              0
            )
          : _vm._e(),
        _vm.repairImg.length == 0
          ? _c("div", { staticClass: "card-cell imgs-cell" }, [
              _c("div", { staticClass: "no-img" }, [_vm._v("未上传现场照片")])
            ])
          : _vm._e()
      ]),
      _c("div", { staticClass: "card-title" }, [_vm._v("报修处理")]),
      _c("div", { staticClass: "card" }, [
        _c("div", { staticClass: "card-cell textarea-cell" }, [
          _vm._m(5),
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.dataObj.handleExplain,
                expression: "dataObj.handleExplain"
              }
            ],
            staticClass: "cell-content textarea",
            attrs: {
              placeholder: "请输入维修描述",
              maxlength: "300",
              rows: "4"
            },
            domProps: { value: _vm.dataObj.handleExplain },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.dataObj, "handleExplain", $event.target.value)
              }
            }
          })
        ]),
        _vm._m(6),
        _c(
          "div",
          { staticClass: "card-cell up-imgs-cell" },
          [
            _vm._l(_vm.fileList, function(item, index) {
              return _c("div", { key: index, staticClass: "up-img-cell" }, [
                _c("img", {
                  staticClass: "up-img",
                  attrs: { src: _vm.imgUrl(item), alt: "" },
                  on: {
                    click: function($event) {
                      return _vm.showImg(item)
                    }
                  }
                }),
                _c("img", {
                  staticClass: "remove-img",
                  attrs: {
                    src: require("../../../assets/img/enterprise/remove.png"),
                    alt: ""
                  },
                  on: {
                    click: function($event) {
                      return _vm.onRemoveFile(index)
                    }
                  }
                })
              ])
            }),
            _vm.fileList.length <= 2
              ? _c(
                  "div",
                  { staticClass: "up-img-cell" },
                  [_c("upload-img", { on: { sendOrgImg: _vm.getOrgImg } })],
                  1
                )
              : _vm._e()
          ],
          2
        ),
        _c("div", { staticClass: "card-cell" }, [
          _vm._m(7),
          _c(
            "div",
            { staticClass: "cell-content" },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.dataObj.isParts,
                    callback: function($$v) {
                      _vm.$set(_vm.dataObj, "isParts", $$v)
                    },
                    expression: "dataObj.isParts"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("是")]),
                  _c("el-radio", { attrs: { label: 0 } }, [_vm._v("否")])
                ],
                1
              )
            ],
            1
          )
        ]),
        _vm.dataObj.isParts === 1
          ? _c("div", { staticClass: "card-cell" }, [
              _vm._m(8),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.dataObj.consumables,
                    expression: "dataObj.consumables"
                  }
                ],
                staticClass: "cell-content",
                attrs: { placeholder: "请输入使用的配件信息", maxlength: "20" },
                domProps: { value: _vm.dataObj.consumables },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.dataObj, "consumables", $event.target.value)
                  }
                }
              })
            ])
          : _vm._e()
      ]),
      _c(
        "div",
        { staticClass: "but-cell" },
        [
          _c(
            "mt-button",
            {
              staticClass: "save-but",
              attrs: { loading: _vm.submiting, type: "primary" },
              on: { click: _vm.onSubmit }
            },
            [_vm._v("提交")]
          )
        ],
        1
      ),
      _c(
        "mt-popup",
        {
          model: {
            value: _vm.showImgFlag,
            callback: function($$v) {
              _vm.showImgFlag = $$v
            },
            expression: "showImgFlag"
          }
        },
        [_c("img", { staticClass: "big-img", attrs: { src: _vm.tempImgUrl } })]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "cell-title" }, [
      _c("span", { staticClass: "required" }, [_vm._v("*")]),
      _c("span", [_vm._v("设备名称")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "cell-title" }, [
      _c("span", { staticClass: "required" }, [_vm._v("*")]),
      _c("span", [_vm._v("报修位置")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "cell-title" }, [
      _c("span", { staticClass: "required" }, [_vm._v("*")]),
      _c("span", [_vm._v("详情描述")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "cell-title" }, [
      _c("span", { staticClass: "required" }, [_vm._v("*")]),
      _c("span", [_vm._v("报修联系人")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "cell-title" }, [
      _c("span", { staticClass: "required" }, [_vm._v("*")]),
      _c("span", [_vm._v("联系电话")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "cell-title" }, [
      _c("span", { staticClass: "required" }, [_vm._v("*")]),
      _c("span", [_vm._v("维修描述")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "imgs-cell-title" }, [
      _c("span", { staticClass: "required" }, [_vm._v("*")]),
      _c("span", [_vm._v("维修照片")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "cell-title" }, [
      _c("span", { staticClass: "required" }, [_vm._v("*")]),
      _c("span", [_vm._v("是否使用配件")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "cell-title" }, [
      _c("span", { staticClass: "required" }, [_vm._v("*")]),
      _c("span", [_vm._v("使用配件")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }